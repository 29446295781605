import API from './API';

const ApiTrades = {
  tradesList: (params) => {
    return new API().get(`/mock_trade/user-trade/get_trades_by_user`, params);
  },
  setupTradesData: (params) => {
    return new API().get('/mock_trade/user-trade-setup/get_by_setup_id', params);
  },
  buyTrade: (params) => {
    const queryString = new URLSearchParams(params).toString();
    const requestBody = {
      data: {
        type: 'user-trades',
        attributes: params,
      },
    };
    return new API().post(`mock_trade/user-trade/setup_trade/?${queryString}`, requestBody, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    });
  },
};

export default ApiTrades;
