import { Box, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { ButtonGroup } from '../../../components/Button';
import Play from '../../../components/Play';
import { fetchPostDetails } from '../../../redux/actions/landing_home.action';
import useStyles from './index.style';

const Learn = ({ scrollToRef }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const { categories, loading } = useSelector((store) => {
    const landing_home_store = store.landing_home;

    if (landing_home_store) return landing_home_store.toJS();

    return {};
  });

  const [tab, setTab] = useState('All');
  const navigation = [
    'All',
    'Trend',
    'Support & Resistance',
    'Multiple Time Frame Analysis',
    'Tutorial',
  ];

  const items = [
    {
      title: 'Blockchain as a long-term strategic investment?',
      content: 'Is blockchain a good asset for your long-term investments?',
      image: '/images/content/learn-pic-1.jpg',
      image2x: '/images/content/learn-pic-1@2x.jpg',
      url: '/learn-crypto-details',
    },
    {
      title: 'What the Hedge Funds don’t want you to know',
      content: 'Learn the process driven approach of the “smart money”',
      date: 'Jun 1, 2021',
      image: '/images/content/learn-pic-2.jpg',
      image2x: '/images/content/learn-pic-2@2x.jpg',
      url: '/learn-crypto-details',
    },
    {
      title: 'Trends are trendy',
      content: 'What is trend and why does it matter',
      date: 'Jun 1, 2021',
      play: true,
      image: '/images/content/learn-pic-4.jpg',
      image2x: '/images/content/learn-pic-4@2x.jpg',
      url: '/learn-crypto-details',
    },
    {
      title: 'Risk Management 101 - Stop Loss',
      content: 'Why a stop loss is like the seatbelt in your car',
      date: 'Jun 1, 2021',
      image: '/images/content/learn-pic-3.jpg',
      image2x: '/images/content/learn-pic-3@2x.jpg',
      url: '/learn-crypto-details',
    },
  ];

  const handleTab = (selected) => {
    setTab(selected);
  };

  const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => {
    return <button {...props}>{children}</button>;
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="14" /> */}</SlickArrow>,
    prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="14" /> */}</SlickArrow>,
    responsive: [
      {
        breakpoint: 100000,
        settings: 'unslick',
      },
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    let options = { month: 'short', day: 'numeric', year: 'numeric' };
    return newDate.toLocaleString('en-US', options);
  };

  const handleLink = (post) => {
    dispatch(fetchPostDetails(post.id));
    const slug = post.title
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
    history.push({ pathname: `/article-details/${slug}` });
  };

  const blogPostSkeleton = () => {
    return (
      <>
        {items.map((x, index) =>
          index < 1 ? (
            <Link className={styles.item} to={x.url} key={index}>
              <Box className={styles.preview}>
                <Skeleton key={x.image} animation="wave" variant="rect" width="100%">
                  <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Card" />
                </Skeleton>
              </Box>
              <Box className={styles.line}>
                <Box className={styles.wrap}>
                  <Skeleton key={x.image} animation="wave">
                    <Box className={styles.subtitle}>{x.title}bbbbbb</Box>
                  </Skeleton>
                  <Skeleton key={x.image} animation="wave">
                    <Box className={styles.content}>{x.content}</Box>
                  </Skeleton>
                </Box>
              </Box>
            </Link>
          ) : (
            <Link className={styles.item} to={x.url} key={index}>
              <Box className={styles.preview}>
                <Skeleton key={x.image} animation="wave" variant="rect" width="100%">
                  <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Card" />
                </Skeleton>
              </Box>
              <Box className={styles.details}>
                <Skeleton key={x.image} animation="wave">
                  <Typography className={styles.subtitle} variant="h4">
                    {x.title}aaaaaaa
                  </Typography>
                </Skeleton>
                <Skeleton key={x.image} animation="wave">
                  <Typography className={styles.content}>{x.content}</Typography>
                </Skeleton>
                <Skeleton key={x.image} animation="wave">
                  {x.date && <Typography className={styles.date}>{x.date}</Typography>}
                </Skeleton>
              </Box>
            </Link>
          )
        )}
      </>
    );
  };

  const blogPost = () => {
    return categories?.map((category, cIndex) =>
      category?.attributes?.posts?.map((post, pIndex) => (
        <Box className={styles.item} onClick={() => handleLink(category.attributes?.posts[0])}>
          <Box className={styles.preview}>
            {post?.thumbnail ? (
              <img src={post?.thumbnail} alt="Card" />
            ) : (
              <img src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg" />
            )}
            {category.play && <Play small />}
          </Box>
          <Box className={styles.details}>
            <Typography className={styles.subtitle} variant="h4">
              {post?.title}
            </Typography>
            <Typography className={styles.content}>{post?.description}</Typography>
            {post?.date_published && (
              <Typography className={styles.date}>{formatDate(post?.date_published)}</Typography>
            )}
          </Box>
        </Box>
      ))
    );
    // return (
    //   <>
    //       <Box className={styles.item} onClick={() => handleLink(category.attributes?.posts[0])}>
    //         <Box className={styles.preview}>
    //           {category.attributes?.posts[0]?.thumbnail ? (
    //             <img src={category.attributes?.posts[0]?.thumbnail} alt="Card" />
    //           ) : (
    //             <img src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg" />
    //           )}
    //           {category.play && <Play small />}
    //         </Box>
    //         <Box className={styles.details}>
    //           <Typography className={styles.subtitle} variant="h4">
    //             {category.attributes?.posts[0]?.title}
    //           </Typography>
    //           <Typography className={styles.content}>
    //             {category.attributes?.posts[0]?.description}
    //           </Typography>
    //           {category.attributes.posts[0].date_published && (
    //             <Typography className={styles.date}>
    //               {formatDate(category.attributes?.posts[0]?.date_published)}
    //             </Typography>
    //           )}
    //         </Box>
    //       </Box>
    //   </>
    // );
  };

  // const blogPost = () => {
  //   return (
  //     <>
  //       {categories?.map((x, index) =>
  //         x.attributes?.posts?.length > 0 ? (
  //           index < 1 ? (
  //             <Box className={styles.item} onClick={() => handleLink(x.attributes?.posts[0])}>
  //               <Box className={styles.preview}>
  //                 {x.attributes?.posts[0]?.thumbnail ? (
  //                   <img src={x.attributes?.posts[0]?.thumbnail} alt="Card" />
  //                 ) : (
  //                   <img src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg" />
  //                 )}
  //               </Box>
  //               <Box className={styles.line}>
  //                 <Box className={styles.wrap}>
  //                   <Box className={styles.subtitle}>{x.attributes?.posts[0]?.title}</Box>
  //                   <Box className={styles.content}>{x.attributes?.posts[0]?.description}</Box>
  //                 </Box>
  //                 <Contained
  //                   white={false}
  //                   variant="contained"
  //                   color="primary"
  //                   rounded
  //                   small={window.innerWidth <= 800 ? true : false}
  //                 >
  //                   Learn More
  //                 </Contained>
  //               </Box>
  //             </Box>
  //           ) : (
  //             <Box className={styles.item} onClick={() => handleLink(x.attributes?.posts[0])}>
  //               <Box className={styles.preview}>
  //                 {x.attributes?.posts[0]?.thumbnail ? (
  //                   <img src={x.attributes?.posts[0]?.thumbnail} alt="Card" />
  //                 ) : (
  //                   <img src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg" />
  //                 )}
  //                 {x.play && <Play small />}
  //               </Box>
  //               <Box className={styles.details}>
  //                 <Typography className={styles.subtitle} variant="h4">
  //                   {x.attributes?.posts[0]?.title}
  //                 </Typography>
  //                 <Typography className={styles.content}>
  //                   {x.attributes?.posts[0]?.description}
  //                 </Typography>
  //                 {x.attributes.posts[0].date_published && (
  //                   <Typography className={styles.date}>
  //                     {formatDate(x.attributes?.posts[0]?.date_published)}
  //                   </Typography>
  //                 )}
  //               </Box>
  //             </Box>
  //           )
  //         ) : (
  //           <Box
  //             className={styles.item}
  //             onClick={() => handleLink(categories[0].attributes?.posts[0])}
  //           >
  //             <Box className={styles.preview}>
  //               <img src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg" />
  //               {x.play && <Play small />}
  //             </Box>
  //             <Box className={styles.details}>
  //               <Typography className={styles.subtitle} variant="h4">
  //                 Lorem ipsum
  //               </Typography>
  //               <Typography className={styles.content}>
  //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit
  //               </Typography>
  //               <Typography className={styles.date}>{formatDate('2022-08-12')}</Typography>
  //             </Box>
  //           </Box>
  //         )
  //       )}
  //     </>
  //   );
  // };

  return (
    <Box className={styles.container}>
      <Grid container className={styles.head}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={styles.wrap}>
          <Typography className={styles.title} variant="h2">
            The Day Trading Process
          </Typography>
          <Box className={styles.nav}>
            <ButtonGroup buttons={navigation} currentTab={tab} getTab={handleTab} />
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={styles.view}>
          <Contained white={false} variant="contained" color="primary" rounded>
            View more
          </Contained>
        </Grid> */}
      </Grid>
      <Box className={styles.wrapper}>
        <Slider className={classNames('learn-slider', styles.slider)} {...settings}>
          {loading ? blogPostSkeleton() : blogPost()}
        </Slider>
      </Box>
    </Box>
  );
};

export default Learn;
