export const RETRIEVE_TRADES_ACTION = 'trades/RETRIEVE_TRADES_ACTION';
export const RETRIEVE_TRADES_OK = 'trades/RETRIEVE_TRADES_OK';
export const RETRIEVE_TRADES_ERROR = 'trades/RETRIEVE_TRADES_ERROR';
export const RETRIEVE_TRADES_END = 'trades/RETRIEVE_TRADES_END';

export const RETRIEVE_TRADES_SETUP_DATA_ACTION = 'trades/RETRIEVE_TRADES_SETUP_DATA_ACTION';
export const RETRIEVE_TRADES_SETUP_DATA_OK = 'trades/RETRIEVE_TRADES_SETUP_DATA_OK';
export const RETRIEVE_TRADES_SETUP_DATA_ERROR = 'trades/RETRIEVE_TRADES_SETUP_DATA_ERROR';
export const RETRIEVE_TRADES_SETUP_DATA_END = 'trades/RETRIEVE_TRADES_SETUP_DATA_END';

export const SET_BUY_TRADE_ACTION = 'trades/SET_BUY_TRADE_ACTION';
export const SET_BUY_TRADE_OK = 'trades/SET_BUY_TRADE_OK';
export const SET_BUY_TRADE_ERROR = 'trades/SET_BUY_TRADE_ERROR';
export const SET_BUY_TRADE_END = 'trades/SET_BUY_TRADE_END';
