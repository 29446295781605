import { Redirect, Route, Switch } from 'react-router-dom';
import AboutUs from '../containers/AboutUs';
import ArticleDetails from '../containers/ArticleDetails';
import Charts from '../containers/Charts';
import ComingSoon from '../containers/ComingSoon';
import Company from '../containers/Company';
import Components from '../containers/Components';
import Contact from '../containers/Contact';
import Discover from '../containers/Discover';
import EmailVerified from '../containers/EmailVerified';
import LandingHome from '../containers/LandingHome';
import Login1 from '../containers/Login1';
import NotFound from '../containers/NotFound';
import ResetPassword from '../containers/ResetPassword';
import ServerError from '../containers/ServerError';
import Birthday from '../containers/SignUp/Birthday';
import SignUp1 from '../containers/SignUp1';
import TermsOfService from '../containers/TermsOfService';
import CookiePolicy from '../containers/TermsOfService/CookiePolicy';
import PrivacyPolicy from '../containers/TermsOfService/PrivacyPolicy';
import RefundPolicy from '../containers/TermsOfService/RefundPolicy';
import RiskDisclosureAgreement from '../containers/TermsOfService/RiskDisclosureAgreement';
import TermsAndConditions from '../containers/TermsOfService/TermsAndConditions';
import Welcome from '../containers/Welcome';
import { isLoggedIn } from '../helpers/auth';

const public_routes = [
  {
    name: '/welcome',
    exact: true,
    path: '/welcome',
    component: Welcome,
    key: '/welcome',
  },
  {
    name: '/login',
    exact: true,
    path: '/login',
    component: Login1,
    key: '/login',
  },
  {
    name: '/sign-up',
    exact: true,
    path: '/sign-up',
    component: SignUp1,
    key: '/sign-up',
  },
  {
    name: '/company',
    exact: true,
    path: '/company',
    component: Company,
    key: '/company',
  },
  {
    name: '/sign-up/birthday',
    exact: true,
    path: '/sign-up/birthday',
    component: Birthday,
    key: '/sign-up/birthday',
  },
  {
    name: '/reset-password',
    exact: true,
    path: ['/reset-password', '/set-password/'],
    component: ResetPassword,
    key: '/reset-password',
  },
  {
    name: '/coming-soon',
    exact: true,
    path: '/coming-soon',
    component: ComingSoon,
    key: '/coming-soon',
  },
  {
    name: '/email-verify',
    exact: true,
    path: '/email-verify',
    component: EmailVerified,
    key: '/email-verify',
  },
  {
    name: '/server-error',
    exact: true,
    path: '/server-error',
    component: ServerError,
    key: '/server-error',
  },
  {
    name: '/component-style-guidelines',
    exact: true,
    path: '/component-style-guidelines',
    component: Components,
    key: '/component-style-guidelines',
  },
  {
    name: '/component-charts',
    exact: true,
    path: '/component-charts',
    component: Charts,
    key: '/component-charts',
  },
  {
    name: '/discover',
    exact: true,
    path: '/discover',
    component: Discover,
    key: '/discover-page',
  },
  {
    name: '/landing-home',
    exact: true,
    path: '/landing-home',
    component: LandingHome,
    key: '/landing-home',
  },
  {
    name: '/contact',
    exact: true,
    path: '/contact',
    component: Contact,
    key: '/contact',
  },
  {
    name: '/about-us',
    exact: true,
    path: '/about-us',
    component: AboutUs,
    key: '/about-us',
  },
  {
    name: '/legal',
    path: '/legal',
    component: TermsOfService,
    key: '/legal',
  },
  {
    name: '/terms-and-conditions',
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    key: '/terms-and-conditions',
  },
  {
    name: '/cookie-policy',
    path: '/cookie-policy',
    component: CookiePolicy,
    key: '/cookie-policy',
  },
  {
    name: '/privacy-policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    key: '/privacy-policy',
  },
  {
    name: '/risk-disclosure-agreement',
    path: '/risk-disclosure-agreement',
    component: RiskDisclosureAgreement,
    key: '/risk-disclosure-agreement',
  },
  {
    name: '/refund-policy',
    path: '/refund-policy',
    component: RefundPolicy,
    key: '/refund-policy',
  },
  {
    name: '/article-details/',
    path: '/article-details/:title',
    component: ArticleDetails,
    key: '/article-details',
  },
];

const PublicRoutes = (props) => {
  if (props.location.pathname !== '/not-found' && isLoggedIn())
    return <Redirect to="/app/technical-analysis" push from="/app/login" {...props} />;

  return (
    <Switch>
      {public_routes.map(({ key, name, path, exact, component, ...route }, i) => (
        <Route key={`${i}_${key}`} path={path} exact={exact} component={component} />
      ))}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default PublicRoutes;
