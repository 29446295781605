import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: '48px',
    },
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  table: {
    display: 'table',
    width: '100%',
  },
  row: {
    display: 'table-row',
    '&:first-child': {
      '& $col': {
        borderBottom: `1px solid ${colors.n7}`,
        fontSize: theme.typography.captionBold2.fontSize,
        lineHeight: theme.typography.captionBold2.lineHeight,
        fontWeight: theme.typography.captionBold2.fontWeight,
        color: colors.n3,
        ':root :global(.dark-mode)': {
          borderColor: colors.n2,
          color: colors.n5,
        },
      },
    },
    '&:not(:first-child):not(:last-child)': {
      '& $col': {
        borderBottom: `1px solid ${colors.n6}`,
        ':root :global(.dark-mode)': {
          borderColor: colors.n3,
        },
      },
    },
  },
  col: {
    display: 'table-cell',
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:nth-child(2)': {
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
  content: {
    fontWeight: 500,
  },
  note: {
    color: colors.n4,
  },
  location: {
    // background: colors.n8,
    // color: colors.n2,
    minWidth: '64px',
    display: 'inline-block',
    padding: '8px 8px 6px',
    background: colors.n2,
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.n8,
  },
  btns: {
    marginTop: '36px',
    textAlign: 'right',
    '@media(minWidth: 767px)': {
      marginTop: '16px',
      '& button': {
        width: '100%',
      },
    },
  },
  errorStatus: {
    minWidth: '64px',
    display: 'inline-block',
    padding: '8px 8px 6px',
    background: colors.p3,
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.n8,
  },

  successStatus: {
    minWidth: '64px',
    display: 'inline-block',
    padding: '8px 8px 6px',
    background: colors.p4,
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.n8,
  },
}));

export default useStyles;
