import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Collapse,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from 'react-redux';
import { tradesSelector } from '../../redux/reselect/tradesSelector';
import useStyle from './index.style';
import { RETRIEVE_TRADES_ACTION } from '../../redux/constants/trades.constant';

const Row = ({ row, theme, styles }) => {
  const [open, setOpen] = useState(false);
  const [closePrice, setClosePrice] = useState(row.close_price || '');

  const handleClosePriceChange = (event) => {
    setClosePrice(event.target.value);
  };

  const formatDate = (date) => {
    return new Date(date)
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      })
      .replace(',', ' ');
  };

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset', fontSize: { xs: '0.75rem', sm: '1rem' } } }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
          {row.updated_date ? formatDate(row.updated_date) : '-'}
        </TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>{row.ticker || '-'}</TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>{row.shares || '-'}</TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>{row.entry || '-'}</TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
          {row.is_active ? 'In progress' : row.closed_date ? 'Closed' : 'Open'}
        </TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>{row.type || '-'}</TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
          {row.close_price || '-'}
          {/* <input 
            type="text" 
            value={closePrice} 
            onChange={handleClosePriceChange} 
            style={{ width: '100%', border: 'none', outline: 'none' }}
          /> */}
        </TableCell>
        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
          {row.closed_date ? formatDate(row.closed_date) : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            background: theme.palette.primary.mainGradient,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                sx={{ color: 'white', fontWeight: 'bold', fontSize: { xs: '1rem', sm: '1.2rem' } }}
                variant="h6"
                gutterBottom
              >
                Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      Direction
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      Target
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      Stop Loss
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      Side
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      Asset Class
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={`${row.trade_id}details`}>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      {row.direction || '-'}
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      {row.target || '-'}
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      {row.stop_loss || '-'}
                    </TableCell>
                    <TableCell>
                      <Box className={row.closed_date ? styles.sellStatus : styles.buyStatus}>
                        {row.closed_date ? 'Sell' : 'Buy'}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                      {row.asset_class || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const NoData = () => (
  <TableRow>
    <TableCell colSpan={18} style={{ position: 'relative', height: '100px' }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '100%',
          whiteSpace: 'nowrap',
        }}
      >
        No Data
      </div>
    </TableCell>
  </TableRow>
);

export default function BasicTable({ type = 'open' }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data } = useSelector(tradesSelector);
  const styles = useStyle();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    dispatch({
      type: RETRIEVE_TRADES_ACTION,
      params: {
        user_id: 242,
        page_size: rowsPerPage,
        page: page,
        ...(type === 'trade' && { is_active: 'False', closed_date: 'exists' }),
        ...(type === 'open' && { is_active: 'True' }),
        // params: { user_id: 242, page_size: rowsPerPage, page: page, filters: { type } },
      },
    });
  }, [page]);

  const getTableContent = () => {
    const tradesData = data?.results;

    if (!tradesData || tradesData.length === 0) {
      return <NoData />;
    }

    return tradesData.map((row) => (
      <Row key={row.trade_id} row={row} theme={theme} styles={styles} />
    ));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ '& > *': { fontSize: { xs: '0.75rem', sm: '1rem' } } }}>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }} />
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Date</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Ticker</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Quantity</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Entry</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Activity</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Type</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Close Price</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>Close Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{getTableContent()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
