import React, { useEffect } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RETRIEVE_TRADES_SETUP_DATA_ACTION } from '../../redux/constants/trades.constant';
import Profile from '../../components/Profile';
import useStyle from './index.style';
import { tradeSetupDataSelector } from '../../redux/reselect/tradesSelector';
import BasicTabs from './Tabs';
import TradeOverviewItems from './TradeOverviewItems';

const Trades = () => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const { data: tradeSetupData } = useSelector(tradeSetupDataSelector);
  const { attributes } = tradeSetupData || {};

  useEffect(() => {
    dispatch({
      type: RETRIEVE_TRADES_SETUP_DATA_ACTION,
      params: { setup_id: 1 },
    });
  }, [dispatch]);

  return (
    <Profile>
      <Box sx={{ width: '100%' }} display="flex" flexDirection="column">
        <Grid container gap={2}>
          <Grid xs={12}>
            <Typography className={styles.title} variant="h5">
              Profit & Loss ( PNL )
            </Typography>
          </Grid>
          <TradeOverviewItems attributes={attributes} />
        </Grid>
        <Grid container gap={2} mt={2}>
          <Grid xs={12}>
            <Typography className={styles.title} variant="h5">
              Trades
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Card
              className={styles.card}
              style={{
                flexGrow: 3,
                marginBottom: '0px',
                height: '100%',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  height: '100%',
                  overflow: 'hidden',
                  padding: '16px',
                }}
              >
                <BasicTabs />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Profile>
  );
};

export default Trades;
