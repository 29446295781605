export const labels = ['Bonds', 'Equity'];

export const riskType_values = [
  [80, 20],
  [55, 45],
  [35, 65],
  [20, 80],
  [10, 90],
];

export const riskTypes = [
  'Very Conservative',
  'Conservative',
  'Moderate',
  'Aggressive',
  'Very Aggressive',
];

export const disclaimers = [
  [
    'Inflation-Protected Bonds (VTIP)',
    'Equity: SPY',
    'Gold: GLD',
    'Long-Short Credit:',
    'Managed Futures (ASFYX, AMFAX)',
    'Gold (GLD)',
  ],
  [
    'Corporate Bonds: VCIT',
    'Gov’t Bond: AGG',
    'US Equities: SPY',
    'International Equities: IEFA',
    'Emerging Market Equities: VWO',
  ],
  [
    'Inflation-Protected Bonds (VTIP)',
    'Managed Futures (ASFYX, AMFAX)',
    'Gold (GLD)',
    'Bitcoin (BTC-USD)',
  ],
];

export const shadesOfBlue = [
  '#d1dbe4',
  '#88a2c4',
  '#a3b7ca',
  '#7593af',
  '#476f95',
  '#194a7a',
  '#03045e',
  '#262d79',
  '#475492',
  '#677bab',
];

export const CATEGORY_TITLES_TRADITIONAL = [
  'Equity',
  'Inflation Protected Bond',
  'Alternative Investments',
  'Managed Futures',
  'Equity Market Neutral',
];

export const CATEGORY_TITLES_BITCOIN = ['Cash', 'Managed Futures', 'Precious Metals', 'Blockchain'];

export const PERFORMANCE_RISK_RETURN = [
  'Annualized Return',
  'Annualized Std',
  'Maximum Drawdown',
  'Maximum Drawdown Duration',
];

export const PERFORMANCE_RATIOS = [
  'Sharpe Ratio',
  'Calmar Ratio',
  'Sortino Ratio',
  'Kelly Criterion',
];

export const CHART_COLORS = [
  '#E51857',
  '#DF6B52',
  '#DF52D1',
  '#1D7CEF',
  '#F0AA4B',
  '#2CC1B9',
  '#0058ff',
  '#5C9CF3',
  '#72ABF8',
  '#FC3472',
];
