import { createSelector } from 'reselect';

const selectTrades = (store) => {
  const trades = store?.trades;
  return trades;
};

const selectTradeSetupData = (store) => {
  const trades = store?.trades?.toJS();
  return trades?.tradesSetup;
};

export const tradesSelector = createSelector([selectTrades], (trades) => trades?.toJS() || {});
export const tradeSetupDataSelector = createSelector(
  [selectTradeSetupData],
  (tradeSetupData) => tradeSetupData || {}
);
