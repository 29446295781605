import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { useHistory } from 'react-router-dom';
import useStyle from './index.style';

import {
  editFormAction,
  modalCloseAction,
  resendVerifyEmailAction,
  resetAction,
  setErrorAction,
  signUpAction,
} from '../../redux/actions/signup.action';

import {
  validateConfirmPassword,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '../../helpers/validations';

import { openSnackbarAction } from '../../redux/actions/app.action';
import { isFormValid } from '../../redux/reselect/signUp';
import Desktop from './Desktop';
import Mobile from './Mobile';

const SignUp1 = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const validForm = useSelector(isFormValid);
  const [termsAgree, setTermsAgree] = useState(false);
  const [showPassword, setShowPassword] = useState([false, false]);
  const { form, errors, loading, successModal, email_resend_loading, email_resend_done } =
    useSelector((store) => store?.signUp?.toJS()) || {};

  const auth = useSelector((store) => store?.auth);
  const notify = !!auth && auth.get('notify');
  const message = !!auth && auth.get('message');

  useEffect(() => {
    return () => {
      dispatch(resetAction());
    };
  }, []);

  useEffect(() => {
    if (!!notify && !!message) {
      dispatch(openSnackbarAction(notify, message));
    }
  }, [notify, message]);

  const handleRecaptchaOnChange = (value) => {};

  const handleResendEmail = () => {
    const email = form.email;
    dispatch(resendVerifyEmailAction({ email }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const allFieldsValid = validateAll();

    if (!allFieldsValid) {
      return false;
    }

    dispatch(signUpAction(form));
  };

  const handleOnChange = (e) => {
    const { id: key, value } = e.target;

    dispatch(editFormAction(key, value));
  };

  const validateAll = () => {
    return (
      handleValidate({ target: { id: 'first_name', value: form.first_name } }) &&
      handleValidate({ target: { id: 'last_name', value: form.last_name } }) &&
      handleValidate({ target: { id: 'email', value: form.email } }) &&
      handleValidate({ target: { id: 'password', value: form.password } }) &&
      handleValidate({ target: { id: 'confirm_password', value: form.confirm_password } })
    );
  };

  const handleValidate = (e) => {
    const { id, value } = e.target;
    let errors = [];

    switch (id) {
      case 'first_name':
        errors = validateFirstName(value);
        break;

      case 'last_name':
        errors = validateLastName(value);
        break;

      case 'email':
        errors = validateEmail(value);
        break;

      case 'password':
        errors = validatePassword(value);
        break;

      case 'confirm_password':
        errors = validateConfirmPassword(value, form.password);
        break;

      default:
        break;
    }

    if (!!errors.length) {
      dispatch(setErrorAction(id, errors));

      return false;
    }

    return true;
  };

  const handleModalClose = () => {
    dispatch(modalCloseAction());
  };

  const handleTogglePasswordVisibility = (index) => {
    const newShowPassword = [...showPassword];
    newShowPassword[index] = !newShowPassword[index];
    setShowPassword(newShowPassword);
  };

  return (
    <Box width="100%">
      <Desktop
        classes={classes}
        validForm={validForm}
        termsAgree={termsAgree}
        setTermsAgree={setTermsAgree}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        form={form}
        errors={errors}
        loading={loading}
        successModal={successModal}
        handleSubmit={handleSubmit}
        handleOnChange={handleOnChange}
        validateAll={validateAll}
        handleValidate={handleValidate}
        handleModalClose={handleModalClose}
        handleTogglePasswordVisibility={handleTogglePasswordVisibility}
        email_resend_loading={email_resend_loading}
        email_resend_done={email_resend_done}
        handleResendEmail={handleResendEmail}
        handleRecaptchaOnChange={handleRecaptchaOnChange}
      />
      <Mobile
        classes={classes}
        validForm={validForm}
        termsAgree={termsAgree}
        setTermsAgree={setTermsAgree}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        form={form}
        errors={errors}
        loading={loading}
        successModal={successModal}
        handleSubmit={handleSubmit}
        handleOnChange={handleOnChange}
        validateAll={validateAll}
        handleValidate={handleValidate}
        handleModalClose={handleModalClose}
        handleTogglePasswordVisibility={handleTogglePasswordVisibility}
        email_resend_loading={email_resend_loading}
        email_resend_done={email_resend_done}
        handleResendEmail={handleResendEmail}
      />
    </Box>
  );
};

export default SignUp1;
