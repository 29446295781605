import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { ERROR } from '../../constants';

import { Contained } from '../../components/Button';
import Switch from '../../components/Switch';
import TextInput from '../../components/TextInput';
import VerifyEmailModal from './VerifyEmail';

const Desktop = ({
  dispatch,
  history,
  classes,

  form,
  errors,
  general_error,
  unverified_email_modal,

  showPassword,
  setShowPassword,

  remember_me,
  setRememberMe,

  handleLoginClick,
  handleTogglePasswordVisibility,
  handleOnChange,
  handleSubmit,
  validateAll,
  handleValidate,

  ...props
}) => {
  const { loading } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });
  return (
    <Hidden smDown>
      <Box className={classes.container}>
        <VerifyEmailModal open={unverified_email_modal} />
        <Box className={classes.content}>
          {/* <Hidden smDown>
            <Box className={classes.bgContainer}>
              <Grid container spacing={0}>
                <Grid item xl={6} lg={6} md={6} sm={0} />
                <Grid item xl={6} lg={6} md={6} sm={0}>
                  <Box className={classes.imgBg}>
                    <img src={login_bg} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Hidden> */}
          <Grid className={classes.gridContainer} container spacing={0}>
            <Grid item className={classes.formGridItem}>
              <Box
                height="100%"
                alignItems="center"
                display="flex"
                flexDirection="column"
                paddingTop="150px"
              >
                <Box minWidth="30%">
                  <Box textAlign="center" className={classes.signIn}>
                    <Typography variant="h5">Sign In</Typography>
                  </Box>
                  {/* <Box paddingTop="16px" className={classes.signInDesc}>
                    <Typography variant="h6">Enter your email and password to sign in</Typography>
                  </Box> */}
                  <Box paddingTop="60px" zIndex="100">
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                      <Box>
                        <TextInput
                          id="username"
                          placeholder="Email"
                          // label="Email"
                          magic_label="Email"
                          inputMode="email"
                          value={form.username}
                          onChange={handleOnChange}
                          conditions={errors.username}
                          inputState={errors?.username?.length && ERROR}
                          variant="standard"
                        />
                      </Box>
                      <Box paddingTop="1rem">
                        <TextInput
                          id="password"
                          placeholder="Password"
                          magic_label="Password"
                          value={form.password}
                          onChange={handleOnChange}
                          type={showPassword[0] ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.inputAdornment}
                                position="end"
                                tabIndex={-1}
                              >
                                <IconButton
                                  tabIndex={-1}
                                  aria-label="toggle password visibility"
                                  onClick={() => handleTogglePasswordVisibility(0)}
                                >
                                  {showPassword[0] ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          conditions={errors.password}
                          inputState={errors?.password?.length && ERROR}
                          variant="standard"
                        />
                      </Box>
                      <Box textAlign="center" paddingTop="20px" className={classes.noAccount}>
                        <Link to="/reset-password">Forgot Password?</Link>
                      </Box>
                      <Box paddingTop="25px" textAlign="center">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={remember_me}
                              onChange={(e) => setRememberMe(e.target.checked)}
                              name="checkedB"
                            />
                          }
                          label="Remember me"
                        />
                      </Box>
                      <Box paddingTop="30px" textAlign="center">
                        <Contained onClick={handleSubmit} miniRounded type="submit">
                          {!loading ? (
                            'Sign In'
                          ) : (
                            <Stack sx={{ color: 'white.500' }} spacing={2} direction="row">
                              <CircularProgress color="inherit" size={25} />
                            </Stack>
                          )}
                        </Contained>
                      </Box>
                      <Box paddingTop="20px" textAlign="center" className={classes.noAccount}>
                        <span>Don’t have an account? </span>
                        <Link to="/sign-up">Sign Up</Link>
                      </Box>
                      {general_error && (
                        <Box className={classes.generalError}>
                          <Alert severity="error">
                            <Typography variant="body2">
                              Sorry, your email or password is incorrect.
                            </Typography>
                            <Typography variant="body2">
                              Please check your details and try again.
                            </Typography>
                          </Alert>
                        </Box>
                      )}
                    </form>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xl={7} lg={7} md={7}>
              <Box className={classes.bgRoot}>
                <Box className={classes.bgText}>
                  <Typography>Surf</Typography>
                  <Typography>The</Typography>
                  <Typography>Wave</Typography>
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Hidden>
  );
};

export default Desktop;
