import React from 'react';
import cn from 'classnames';
import useStyle from './index.styles';
import TextInput from '../../../components/TextInput';
import { Typography } from '@mui/material';

const Form = ({ goFinish }) => {
  const styles = useStyle();
  return (
    <form className={styles.form}>
      <div className={styles.head}>
        <Typography variant="h4" className={styles.title}>
          Create new password
        </Typography>
        <div className={styles.info}>
          Your new password must be different from previously used passwords
        </div>
      </div>

      <div className={styles.fieldSet}>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          magic_label="Email"
          placeholder="Enter your email"
          variant="standard"
          required
          InputLabelProps={{
            style: { fontSize: '12px' },
          }}
          InputProps={{
            style: { fontSize: '12px' },
          }}
        />

        <TextInput
          className={styles.field}
          name="password"
          type="password"
          magic_label="New Password"
          placeholder="Create a strong password"
          variant="standard"
          required
          view
          InputLabelProps={{
            style: { fontSize: '12px' },
          }}
          InputProps={{
            style: { fontSize: '12px' },
          }}
        />

        <TextInput
          className={styles.field}
          name="confirm-password"
          type="password"
          magic_label="Confirm Password"
          placeholder="Repeat your password"
          variant="standard"
          required
          view
          InputLabelProps={{
            style: { fontSize: '12px' },
          }}
          InputProps={{
            style: { fontSize: '12px' },
          }}
        />
      </div>

      <button className={cn('button-large', styles.button)} onClick={goFinish} type="submit">
        Reset Password
      </button>
    </form>
  );
};

export default Form;
