import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { THEME_DARK_MODE } from '../../constants';

const CardItem = ({ className, noMargin, title, desc, image, path, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useSelector((store) => store.app.get('theme'));
  const isDarkTheme = theme === THEME_DARK_MODE;
  const { pathname } = history.location;

  return (
    <Card className={clsx(classes.card, className, { [classes.noMargin]: noMargin })} {...props}>
      <CardActionArea
        onClick={() =>
          history.push(
            `${
              pathname.includes('/app/technical-analysis') ? '/app/technical-analysis' : ''
            }${path}`
          )
        }
      >
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="column">
            <Box className={classes.cardImg} flex={1} py="5px">
              <img src={image} />
            </Box>
            <Box className={clsx(classes.title, { [classes.darkTitle]: isDarkTheme })}>
              <Typography variant="h6">{title}</Typography>
            </Box>
            <Box className={classes.desc}>
              <Typography variant="body1">{desc}</Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardItem;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.card.base,
    borderRadius: '12px',
    margin: '14px',
    flex: 1,
    // height: '210px',
    // cursor: 'pointer',

    [theme.breakpoints.down('md')]: {
      margin: '10px',
      minWidth: '320px',
      maxWidth: '450px',
    },
  },
  content: {
    height: '100%',

    '& > div': {
      height: '100%',
    },
  },
  noMargin: {
    margin: 0,
  },
  title: {
    paddingTop: '1rem',
    display: 'flex',
    flex: 1,
    alignItems: 'center',

    '& h6': {
      background: theme.palette.gradients.blue_linear,
      fontWeight: 'bold',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      color: theme.palette.common.white,
    },
  },
  darkTitle: {
    '& h5': {
      '-webkit-text-fill-color': 'unset',
    },
  },
  desc: {
    fontSize: '.875rem',
    display: 'flex',
    flex: 2,
    color: theme.palette.card.desc,
  },
  arrow: {
    paddingTop: '.75rem',

    '& a': {
      marginLeft: '-8px',
    },
  },
  cardImg: {
    display: 'flex',
    flex: 1,

    '& img': {
      width: '100%',
      maxWidth: '50px',
      maxHeight: '60px',
    },
  },
}));
