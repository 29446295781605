import { Fade, IconButton, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import close_icon from '../../assets/images/icons/close.png';

const BaseModal = ({
  Backdrop,
  children,
  className,
  closeAfterTransition,
  handleClose,
  open,
  hideCloseButton = false,
  title = '',
  cancelButtonText = 'Discard',
  saveButtonText = 'Save',
  onSave = () => {},
  onDiscard = () => {},
  isValidToSave = true,
  ...props
}) => {
  const classes = useStyles({ title });
  return (
    <Modal
      className={clsx(className, classes.modal)}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {title && (
            <div className={classes.header}>
              <button
                onClick={onDiscard}
                className={clsx(classes.discardButton, classes.actionButton)}
              >
                {cancelButtonText}
              </button>{' '}
              <h2>{title}</h2>
              <button
                onClick={onSave}
                disabled={!isValidToSave}
                className={clsx(classes.saveButton, classes.actionButton)}
              >
                {saveButtonText}
              </button>{' '}
            </div>
          )}
          <div className={classes.body}>
            {hideCloseButton && (
              <IconButton onClick={handleClose} className={classes.closeButton}>
                <img src={close_icon} alt="close icon" />
              </IconButton>
            )}
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BaseModal;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    minWidth: '350px',
    maxWidth: '90%',
    maxHeight: '95%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 10,

    '&:focus-visible': {
      outline: 'none',
    },
  },
  body: {
    padding: ({ title }) => `${title ? 0 : 30}px 40px 30px`,

    [theme.breakpoints.down('sm')]: {
      padding: ({ title }) => `${title ? 0 : 20}px 20px 20px`,
      minWidth: 'inherit',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '12px 0px',
    color: 'white',
    background: theme.palette.gradients.blue_linear,
    marginBottom: '8px',
  },
  actionButton: {
    color: 'white',
    fontWeight: 'bold',
    padding: '0px 8px',
  },
  saveButton: {
    opacity: 1,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  discardButton: {
    //discard Button classes
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));
