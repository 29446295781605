import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, useMediaQuery, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EducationSVG } from '../../../../assets/icons/header/education.svg';
import { ReactComponent as GlobalMacroSVG } from '../../../../assets/icons/header/global_macro.svg';
import { ReactComponent as PortfolioManagementSVG } from '../../../../assets/icons/header/portfolio_management.svg';
import { ReactComponent as StockAnalysisSVG } from '../../../../assets/icons/header/stock_analysis.svg';
import { ReactComponent as TradesHistorySVG } from '../../../../assets/icons/header/trades_history.svg';
import { ReactComponent as StockSentimentSVG } from '../../../../assets/icons/header/stock_sentiment.svg';
import useStyles from '../index.style';

const Menu = ({ onClose }) => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentPath = history.location.pathname.split('/').pop();

  const menu_items = [
    {
      title: 'Stock Analysis',
      slug: 'technical-analysis',
      icon: <StockAnalysisSVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />,
    },
    {
      title: 'Trades',
      slug: 'trades',
      icon: <TradesHistorySVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />,
    },
    {
      title: 'Portfolio Management',
      slug: 'risk-aware-profile',
      icon: <PortfolioManagementSVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />,
    },
    {
      title: 'Global Macro',
      slug: 'macroeconomics',
      icon: <GlobalMacroSVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />,
    },
    {
      title: 'Stock Sentiment',
      slug: 'sentiment',
      icon: <StockSentimentSVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />,
    },
    {
      title: 'Education',
      slug: 'challenge',
      icon: <EducationSVG style={{ color: isMobile ? '#000000' : '#ffffff' }} width="35" height="35" />
    },
  ];

  const handleClick = (slug) => {
    if (onClose) onClose();
    history.push('/app/' + slug);
  };

  if (isMobile) {
    return (
      <Box className={styles.list} sx={{ width: '100%', maxWidth: 360 }}>
        <List disablePadding>
          {menu_items.map((item, key) => (
            <ListItem
              key={`menu-list-item-${key}`}
              className={styles.listItem}
              disableGutters
              sx={{
                borderBottom: currentPath === item.slug ? `2px solid #ffffff` : 'none'
              }}
            >
              <ListItemButton
                className={styles.listItemButton}
                onClick={() => handleClick(item.slug)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className={styles.listItemText} primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 8 }}>
      {menu_items.map((item, key) => (
        <Box
          key={`menu-item-${key}`}
          onClick={() => handleClick(item.slug)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            borderBottom: currentPath === item.slug ? `2px solid #ffffff` : 'none',
            '&:hover': {
              opacity: 0.8
            }
          }}
        >
          {item.icon}
          <Typography fontSize={10}>{item.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Menu;
