import { fromJS } from 'immutable';

import {
  DAILY_STOCK_PRICES_ACTION,
  DAILY_STOCK_PRICES_END,
  DAILY_STOCK_PRICES_ERROR,
  DAILY_STOCK_PRICES_OK,
  EDIT_DATA_ACTION,
  RISK_INDICATOR_ACTION,
  RISK_INDICATOR_END,
  RISK_INDICATOR_ERROR,
  RISK_INDICATOR_OK,
  SEARCH_STOCK_ACTION,
  SEARCH_STOCK_END,
  SEARCH_STOCK_ERROR,
  SEARCH_STOCK_OK,
  STOCK_PRICES_ACTION,
  STOCK_PRICES_START,
  STOCK_PRICES_END,
  STOCK_PRICES_ERROR,
  STOCK_PRICES_OK,
  STOCK_PRICES_EMPTY,
  SET_CURRENT_PAGE,
  RESET_CURRENT_PAGE,
  STOCK_ENTRY_PRICE_OK,
  STOCK_ENTRY_PRICE_START,
  STOCK_ENTRY_PRICE_END,
} from '../constants/stock.constant';

const initial_state = fromJS({
  data: [],
  links: {},
  meta: {},
  loading: false,
  error: false,
  ticker: {
    ticker: 'SPY',
    name: 'SPDR S&P 500 ETF Trust',
    type: 'stock',
  },
  entry_price: {
    loading: false,
    close: null,
    close_vwa: null,
    datetime: null,
    high: null,
    low: null,
    number_transactions: null,
    open: null,
    pre_post_market: null,
    timezone: 'US/Eastern',
    volume: null,
  },
  vixValue: null,
  stock_prices: {
    data: undefined,
    loading: false,
  },
  risk_indicator: {
    data: undefined,
    loading: false,
  },
  search: {
    loading: false,
  },
  currentPage: 1,
});

const stocksReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return state.set('currentPage', action.page);

    case RESET_CURRENT_PAGE:
      return state.set('currentPage', 1);

    case SEARCH_STOCK_ACTION:
      return state.set(['search', 'loading'], true);

    case SEARCH_STOCK_OK:
      return state
        .set('data', action.data.data)
        .set('links', action.data.links)
        .set('meta', action.data.meta);

    case SEARCH_STOCK_ERROR:
      return state.set('error', true);

    case SEARCH_STOCK_END:
      return state.set(['search', 'loading'], false).set('error', false);

    case EDIT_DATA_ACTION:
      return state.set(action.key, action.value);

    case DAILY_STOCK_PRICES_ACTION: {
      return state.set(['stock_prices', 'loading'], true);
    }

    case DAILY_STOCK_PRICES_OK: {
      return state.set('vixValue', action.data);
    }

    case DAILY_STOCK_PRICES_ERROR:
      return state.set('error', action);

    case DAILY_STOCK_PRICES_END:
      return state.set(['stock_prices', 'loading'], false).set('error', false);

    case STOCK_PRICES_START:
      return state.setIn(['stock_prices', 'loading'], true);

    case STOCK_PRICES_ACTION:
      return state.setIn(['stock_prices', 'loading'], true);

    case STOCK_PRICES_OK:
      return state.updateIn(['stock_prices', 'data'], (data) => {
        const { data: actionData, page } = action.payload;

        if (data) {
          const existingResults = data.price_data.results;
          const newResults = actionData.price_data.results;
          let updatedResults;

          // If page is 1, replace the results with the new data
          if (page === 1) {
            updatedResults = newResults;
          }
          // If the existing results call repeated, keep the existing results
          else if (existingResults.length / 200 >= page) {
            updatedResults = existingResults;
          }
          // Otherwise, merge the new results with the existing ones
          else {
            updatedResults = [...existingResults, ...newResults];
          }

          return {
            ...actionData,
            price_data: {
              ...actionData.price_data,
              results: updatedResults,
            },
          };
        }

        // If no existing data, return the new action data
        return actionData;
      });

    case STOCK_PRICES_ERROR:
    case STOCK_PRICES_END:
      return state.setIn(['stock_prices', 'loading'], false);

    case STOCK_ENTRY_PRICE_OK:
      return state.set('entry_price', { ...action.data, loading: false });

    case STOCK_ENTRY_PRICE_START:
      return state.setIn(['entry_price', 'loading'], true);

    case STOCK_ENTRY_PRICE_END:
      return state.setIn(['entry_price', 'loading'], false);

    case STOCK_PRICES_EMPTY:
      return state.setIn(['stock_prices', 'data'], null);

    case RISK_INDICATOR_ACTION:
      return state.setIn(['risk_indicator', 'loading'], true);
    case RISK_INDICATOR_OK:
      return state.setIn(['risk_indicator', 'data'], action.data);
    case RISK_INDICATOR_ERROR:
    case RISK_INDICATOR_END:
      return state.setIn(['risk_indicator', 'loading'], false);

    default:
      return state;
  }
};

export default stocksReducer;
