import React, { useState } from "react";
import * as d3 from "d3";
import { useTheme, Box, Typography, Grid } from '@mui/material';


const Arc = ({ data, totalCount, index, createArc, color, onHover }) => {

    const handleMouseOver = () => {
        const percentage = ((data.value / totalCount) * 100).toFixed(2);
        onHover({ name: data.data.name, value: data.value, percentage });
    };

    const handleMouseOut = () => {
        onHover(null);
    };

    return (
        <g key={index} className="arc" cursor='pointer' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <path className="arc" d={createArc(data)} fill={color} />
            <text
                transform={`translate(${createArc.centroid(data)})`}
                textAnchor="middle"
                fill="white"
                fontSize="15"
                fontWeight="bold"
            >
                {`${data.value}`}
            </text>
        </g>
    )
};

const Pie = ({ data, title, totalCount, width, height, innerRadius, outerRadius }) => {
    const theme = useTheme();
    const createPie = d3
        .pie()
        .value(d => d.value)
        .sort(null);

    const createArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    const colors = ["#41E7B0", "#F2185B", "#5D5FEF"];

    const chartData = createPie(data);
    const [hoveredValue, setHoveredValue] = useState(null);
    const commonTextStyle = {
        background: theme.palette.gradients.blue_linear,
        fontWeight: 'bold',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {title !== "" && (
                <Typography variant="h6" sx={{ ...commonTextStyle, marginBottom: 2 }}>
                    {`${title} (${totalCount || 0})`}
                </Typography>
            )}
            {hoveredValue ? (
                <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                    <Box sx={{ display: 'flex' }} gap={1}>
                        <Typography style={commonTextStyle}>{`${hoveredValue.name}: `}</Typography>
                        <Typography>{`${hoveredValue.percentage}%`}</Typography>
                    </Box>
                </Box>
            ) :
                <Box height={24} />
            }
            <svg width={'100%'} height={height} viewBox={`0 0 ${width} ${height}`} display='flex' flexDirection='column' justifyContent='space-between'>
                <g transform={`translate(${outerRadius} ${outerRadius})`}>
                    {chartData.map((d, i) => (
                        <Arc
                            key={i}
                            index={i}
                            data={d}
                            createArc={createArc}
                            color={colors[i]}
                            totalCount={totalCount}
                            onHover={setHoveredValue}
                        />
                    ))}
                </g>
            </svg>
            <Grid container direction="column">
                {chartData.map((d, i) => {
                    return (
                        <Grid item key={d.data.name} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <Box sx={{ display: 'inline-block', width: 20, height: 15, background: colors[i], border: '1px solid black' }} />
                            <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                {d.data.name}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default Pie;
