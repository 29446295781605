import format from 'date-fns/format';
import PropTypes from 'prop-types';

import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Hidden, Tab, Tabs, Typography } from '@mui/material';

import Categories from './components/Categories';
import Description from './components/Description';
import FinishedQuiz from './components/FinishedQuiz';
import GeneratePieChart from './components/GeneratePieChart';
import UnfinishedQuiz from './components/UnfinishedQuiz';
import { disclaimers, labels, riskType_values, riskTypes } from './constant';
import useStyle from './index.style';
import Portfolio from './Portfolio';

import { Outlined } from '../../components/Button';
import { LoadingIndicator } from '../../components/ContainerIndicator';

import { fetchUserDetails } from '../../redux/actions/quiz.action';
import { getPortfolioData } from '../../redux/actions/profile.action';
import { profileSelector } from '../../redux/reselect/profileSelector';
import { quizSelector } from '../../redux/reselect/quizSelector';
// import { getUserSetting } from '../../redux/actions/profile.action';

const checkBreakpoint = () => {
  return window.innerWidth >= 320 && window.innerWidth < 720;
};

const isMobile = window.innerWidth < 720;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={checkBreakpoint() ? 2 : 6}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const RiskAwareLandingPage = (props) => {
  const quiz = useSelector(quizSelector);
  const profile = useSelector(profileSelector);
  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });

  const styles = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [portfolioType, setPortfolioType] = useState('alternative');
  const [disclaimer, setDisclaimer] = useState([]);
  const [selected, setSelected] = useState(null);
  const [riskTypeIndex, setRiskTypeIndex] = useState(0);
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy MMM dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy MMM dd'));

  useEffect(() => {
    if (user_details?.quiz_completed) {
      dispatch(fetchUserDetails());
    }
    setDisclaimer(disclaimers[0]);
  }, []);

  useEffect(() => {
    fetchPortfolio();
    setRiskTypeIndex(
      riskTypes.findIndex((value) => value === profile?.userPortfolio?.data[0]?.attributes?.name)
    );
  }, [portfolioType, profile?.userPortfolio?.data?.length]);

  useEffect(() => {
    setDisclaimer(disclaimers[value]);
  }, [value]);

  const fetchPortfolio = () => {
    if (profile?.userPortfolio) {
      const current = new Date();
      const newDate = new Date(current.getFullYear(), current.getMonth(), 0);
      const start = new Date(newDate).setYear(newDate.getFullYear() - 3);
      const end = newDate;
      const payload = {
        type: portfolioType,
        value: '',
        startDate: format(start, 'yyyy-MM-dd'),
        endDate: format(end, 'yyyy-MM-dd'),
      };

      setStartDate(format(start, 'yyyyMMM dd'));
      setEndDate(format(end, 'yyyyMMM dd'));
      dispatch(getPortfolioData(payload));
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setPortfolioType('traditional');
    } else if (newValue === 2) {
      setPortfolioType('blockchain');
    } else {
      setPortfolioType('alternative');
    }
    setValue(newValue);
  };
  const handleIndex = (value) => {
    setSelected(value);
  };

  const handleClick = () => {
    history.push('/app/risk-aware-profile/create-profile');
  };

  const getContent = () => {
    return user_details?.quiz_completed ? <FinishedQuiz /> : <UnfinishedQuiz />;
  };

  return (
    <>
      {profile.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'stretch'}
            justifyContent={user_details?.quiz_completed && !isMobile ? 'space-between' : 'center'}
          >
            {getContent()}
            {user_details?.quiz_completed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                px={8}
              >
                <Description title={profile?.userPortfolio?.data[0]?.attributes?.name} />
                <Typography className={styles.textFocus} variant="h4">
                  Asset Allocation
                </Typography>
                <Box className={styles.chartsContainer}>
                  {riskType_values.map((value, index) => {
                    return (
                      <GeneratePieChart
                        values={value}
                        labels={labels}
                        index={index}
                        setValue={handleIndex}
                        TYPE={1}
                      />
                    );
                  })}
                </Box>
                <Box display="flex" alignItems="center">
                  <Box height="10px" width="10px" backgroundColor="#f96384" mr={1} />
                  <Typography variant="body1">Equity</Typography>
                  <Box height="10px" width="10px" backgroundColor="#36a2eb" ml={1} />
                  <Typography variant="body1" ml={1}>
                    Bonds
                  </Typography>
                </Box>
                <Categories labels={labels} colors={['#3D94EC', '#EF2966']} align="center" />
              </Box>
            )}
          </Box>
          {user_details?.quiz_completed && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={4}
            >
              <Hidden mdUp>
                <Outlined onClick={handleClick} rounded white={false}>
                  <AddIcon color="primary" />
                  Create Portfolio
                </Outlined>
              </Hidden>
              <Box display="flex" flexDirection="column" mt={4} width="100%">
                <Box className={styles.tabContainer}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className={styles.tabs}
                    variant="scrollable"
                    scrollButtons={false}
                    indicatorColor="none"
                    aria-label="scrollable prevent tabs example"
                  >
                    <Tab
                      className={`${styles.tab} ${value === 0 ? styles.selectedTab : ''}`}
                      label={
                        <Box display="flex" flexDirection="row">
                          Diversified
                          <Box className={styles.recommended} ml={1}>
                            Recommended
                          </Box>
                        </Box>
                      }
                      wrapped
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={`${styles.tab} ${value === 1 ? styles.selectedTab : ''}`}
                      label="Traditional"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={`${styles.tab} ${value === 2 ? styles.selectedTab : ''}`}
                      label="Blockchain"
                      wrapped
                      {...a11yProps(2)}
                    />
                    <Hidden smDown>
                      <Box className={styles.createButton}>
                        <Outlined onClick={handleClick} rounded white={false}>
                          <AddIcon sx={{ color: '#2C81FF' }} />
                          Create Portfolio
                        </Outlined>
                      </Box>
                    </Hidden>
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Portfolio
                      tabValue={value}
                      disclaimers={disclaimer}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Portfolio
                      tabValue={value}
                      disclaimers={disclaimer}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Portfolio
                      tabValue={value}
                      disclaimers={disclaimer}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RiskAwareLandingPage;
