import { fromJS } from 'immutable';
import {
  RETRIEVE_TRADES_ACTION,
  RETRIEVE_TRADES_OK,
  RETRIEVE_TRADES_ERROR,
  RETRIEVE_TRADES_END,
  RETRIEVE_TRADES_SETUP_DATA_ACTION,
  RETRIEVE_TRADES_SETUP_DATA_OK,
  RETRIEVE_TRADES_SETUP_DATA_ERROR,
  RETRIEVE_TRADES_SETUP_DATA_END,
  SET_BUY_TRADE_ACTION,
  SET_BUY_TRADE_OK,
  SET_BUY_TRADE_ERROR,
  SET_BUY_TRADE_END,
} from '../constants/trades.constant';

const initial_state = fromJS({
  data: [],
  loading: false,
  tradesSetup: {
    data: {},
    loading: false,
  },
  buy_trade_loading: false,
  buy_trade_success: false,
  buy_trade_failure: false,
});

const tradesReducer = (state = initial_state, action) => {
  switch (action.type) {
    case RETRIEVE_TRADES_ACTION:
      return state.set('loading', true);

    case RETRIEVE_TRADES_OK:
      return state.set('data', action.data);

    case RETRIEVE_TRADES_ERROR:
      return state;

    case RETRIEVE_TRADES_END:
      return state.set('loading', false);

    case RETRIEVE_TRADES_SETUP_DATA_ACTION:
      return state.setIn(['tradesSetup', 'loading'], true);

    case RETRIEVE_TRADES_SETUP_DATA_OK:
      return state.setIn(['tradesSetup', 'data'], action.data);

    case RETRIEVE_TRADES_SETUP_DATA_ERROR:
      return state;

    case RETRIEVE_TRADES_SETUP_DATA_END:
      return state.setIn(['tradesSetup', 'loading'], false);

    case SET_BUY_TRADE_ACTION:
      return state.set('buy_trade_loading', true);

    case SET_BUY_TRADE_OK:
      return state.set('buy_trade_success', true);

    case SET_BUY_TRADE_ERROR:
      return state.set('buy_trade_failure', true);

    case SET_BUY_TRADE_END:
      return state.set('buy_trade_loading', false);

    default:
      return state;
  }
};

export default tradesReducer;
