import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    background: 'linear-gradient(142.89deg, #76EBC9 0.05%, #59B7E6 66.64%, #F489C0)',
    boxShadow: '0px 60.9596px 91.4394px rgba(167, 209, 233, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '1.5em',
    padding: '2.5em 1rem',
    width: '100%',
  },
  cardItem: {
    borderRadius: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '2rem',
  },
  boldText: {
    fontWeight: 'bold',
  },
  riskTitle: {
    background: 'linear-gradient(89.32deg, #41E7B0 -29.31%, #3273DE 36.91%, #EA95C1 126%)',
    fontWeight: 'bold',
    lineHeight: 1.3,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    textShadow: '2px 2px 2px rgba(255, 255, 255, 0.2)',
  },

  textFocus: {
    background: theme.palette.gradients.gradient_background,
    fontWeight: 'bold',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  textFocus2: {
    background: theme.palette.gradients.gradient_2,
    display: 'inline',
    fontWeight: 'bold',
    '-mozBackgroundClip': 'text',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  whiteText: {
    color: colors.white,
  },

  boldWhiteText: {
    color: colors.white,
    fontWeight: theme.typography.bodyBold1.fontWeight,
  },

  compressedText: {
    color: colors.white,
    lineHeight: 0.1,
  },

  divider: {
    backgroundColor: 'rgba(125, 217, 230, 0.8)',
    height: '2px',
    width: '670px',
  },

  rightText: {
    textAlign: 'right',
  },

  leftText: {
    textAlign: 'left',
  },

  inlineDisplay: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  paragraph: {
    display: 'contents',
  },

  riskProfileContainer: {
    width: '90%',
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    marginRight: '16px',
    alignItems: 'center',
    background: 'linear-gradient(271.29deg, #F178B6 -17.23%, #5D5FEF 36.62%, #41E7B0 104.9%)',
    boxShadow:
      'inset 8.01106px -5.34071px 18.6925px rgba(255, 255, 255, 0.25), inset 0px 10.6814px 10.6814px rgba(0, 0, 0, 0.25)',
    borderRadius: '77.4403px',
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '8px 12px 0 12px',
    },
  },

  chartsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '2rem',
    margin: '2em auto',
  },

  tabContainer: {
    width: '100%',
    background: '#95C6FF',
    marginTop: '0.5rem',
    '& div.parent:nth-child(1)': {
      background: '#95C6FF',
      '& $portfolioContainer': {
        height: '6rem',
        width: '81%',
      },
    },
    '& div.parent:nth-child(2)': {
      background: '#81BBFF',
      '& button': {
        top: '0',
        left: '0px',
        width: '1685px',
        height: '678px',
        position: 'absolute',
        boxSizing: 'border-box',
        objectFit: 'cover',
        borderColor: 'transparent',
        borderRadius: '36px 36px 0 0',
        backgroundColor: 'rgba(148, 197, 255, 1)',
      },
      '& $portfolioContainer': {
        height: '6rem',
        position: 'absolute',
        width: '81%',
        marginTop: '6rem',
      },
    },
    '& div.parent:nth-child(3)': {
      background: '#5DA4F8',
      '& $portfolioContainer': {
        position: 'absolute',
        width: '81%',
        marginTop: '12rem',
      },
    },
  },

  portfolioContainer: {
    width: '100%',
    borderRadius: '36px 36px',
    padding: '15px 5px',
    '& $chartsContainer': {
      '& .MuiTypography-root': {
        background: '#FFF',
        fontWeight: 'bold',
        display: 'inline',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
    },

    '& .loading-indicator': {
      '& svg': {
        fill: 'rgba(255,255,255)',
      },
      '& .MuiTypography-root': {
        '-webkit-text-fill-color': '#FFFFFF !important',
      },
    },
    filter: 'drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15))',
  },

  prosConsContainer: {
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },

  whitebackGround: {
    backgroundColor: '#FFFF',
  },

  infoContainer: {
    margin: '4rem 0',
    borderRadius: '29px',
    padding: '10px',
    textAlign: 'center',
    '& hr': {
      margin: '8px 2px 8px 8px',
      width: '8px',
      borderRadius: '1rem',
      backgroundColor: 'white',
    },
  },

  underlineText: {
    color: colors.white,
    textDecoration: 'underline',
    marginTop: '4px',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  assetTitle: {
    color: '#5488C6',
    fontSize: '2.5rem',
  },

  chartContainer: {
    border: 'solid transparent 15px',

    '&:hover': {
      background: '#5DA4F8',
      borderRadius: '20px',
      color: 'white',
      border: 'solid #5DA4F8 15px',

      '& .MuiTypography-root': {
        background: '#FFF',
        fontWeight: 'bold',
        display: 'inline',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
    },
  },

  selectedItem: {
    background: '#5DA4F8',
    borderRadius: '20px',
    color: 'white',
    zIndex: 1,
    border: 'solid #5DA4F8 15px',
    '& .MuiTypography-root': {
      background: '#FFF',
      fontWeight: 'bold',
      display: 'inline',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      '& .MuiTypography-root': {
        fontSize: '2rem',
      },
    },
  },

  chartContainer2: {
    border: 'solid transparent 15px',
    margin: '0 0 70px 0',

    [theme.breakpoints.down('md')]: {
      margin: '0 0.5rem',
      background: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: '1.43356px 1.43356px 11.4685px -1.51222px rgba(0, 0, 0, 0.15)',
    },
  },

  selectedItem2: {
    borderRadius: '50%',
    background: '#FFFF',
    color: 'white',
    zIndex: 1,
    border: 'solid #FFFF 2.5rem',
    '& .MuiTypography-root': {
      background: '#FFF',
      fontWeight: 'bold',
      display: 'inline',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },

  chartsContainer2: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '4em auto',
    background: '#FFFF',
    borderRadius: '15rem',
    maxHeight: '195px',

    [theme.breakpoints.down('md')]: {
      background: 'inherit',
      flexWrap: 'wrap',
      height: 'auto',
      justifyContent: 'center',
      maxHeight: 'fit-content',
    },

    [theme.breakpoints.down('sm')]: {
      margin: 'auto 0',
    },
  },

  tabCard: {
    marginTop: '10px',
    width: '100%',
  },

  categoriesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'auto',
    background: 'rgba(255, 255, 255, 0.14)',
    borderRadius: '1.5rem',
    marginBottom: '2rem',

    '& #title': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('md')]: {
      background: 'inherit',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& .MuiTypography-root': {
        fontSize: '1rem',
      },
    },
  },

  categoriesParent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  rightAlign: {
    right: '2rem',
    top: '0.4rem',
    position: 'absolute',
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.5rem 1rem 0 1rem',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      '& img': {
        height: '1.5rem !important',
        width: 'auto !important',
      },
    },
  },

  riskDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '-webkit-fill-available',

    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h1': {
        fontSize: '45px',
      },
      '& .MuiTypography-h2': {
        fontSize: '40px',
      },
      '& .MuiTypography-h3': {
        fontSize: '30px',
      },
      '& #risk-trade': {
        marginTop: '22px',
        '& .MuiTypography-body1': {
          fontSize: '12px',
        },
      },
    },
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      background: '#F9FFFE',
      '& #scrollable-auto-tab-2': {
        borderRight: 'none',
      },
    },
    // [theme.breakpoints.down('xl')]: {
    //   '& .MuiTabs-indicator': {
    //     width: '158px !important',
    //   },
    // },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },

  tab: {
    borderRadius: '20px 20px 0 0',
    background: '#95C6FF',
    color: '#FFFFFF !important',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    borderRight: '2px solid white',

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100%/3)',
      fontSize: '0.9rem',
    },
  },

  createButton: {
    display: 'flex',
    zIndex: 1,
    marginBottom: '0.5rem',
    width: 'fit-content',
    right: '0px',
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      position: 'absolute',
      background: '#F9FFFE',
      marginBottom: 0,
      paddingBottom: '1rem',
    },
  },

  hide: {
    display: 'none',
  },

  recommended: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.4rem',
    fontSize: '0.8rem',
    background: '#6791f5',
    color: '#FFFFFF',
    height: '24px',
    width: 'max-content',
    borderRadius: '34.56px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  datesContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '700px',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      flexDirection: 'column',
    },
  },

  dateContainer: {
    marginBottom: '2.2rem',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
    '& .MuiCard-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '1rem',
      borderRadius: '10px',
    },
  },

  startDate: {
    '& .MuiCard-root': {
      borderTop: '1rem #50E3C0 solid',
      '& .MuiTypography-root': {
        color: '#50E3C0',
      },
    },
  },

  endDate: {
    '& .MuiCard-root': {
      borderTop: '1rem #FA6679 solid',
      '& .MuiTypography-root': {
        color: '#FA6679',
      },
    },
  },

  performance: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#FFFFFF',
    width: '100%',
  },

  selectedTab: {
    backgroundColor: '#2C81FF',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
}));

export default useStyles;
