import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  tradesCountText: {
    background: 'white',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  tradesCountBox: {
    padding: '4px',
    gap: '4px',
    border: '2px solid transparent',
    background: 'linear-gradient(350deg, rgba(33, 82, 255, 1), rgba(33, 212, 253, 1))',
    borderRadius: '4px',
  },
  listContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 15px',

      '& > div': {
        margin: '15px',
      },
    },
  },

  card: {
    boxShadow: '0px 0.987013px 4.93506px rgba(0, 0, 0, 0.25)',
    borderRadius: '14.8052px',
  },

  cardContainer: {
    margin: '1em 0',
    padding: '1.5em',
    borderRadius: '1.5em',
  },

  riskIndexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(138.19deg, #FFCA1B 2.08%, #F77455 50.52%, #FE8F1A 94.79%)',
    color: '#fff',
    borderRadius: '1em',
    padding: '1em',
    width: '150px',
    margin: '1.5em auto',
  },

  riskNumber: {
    fontWeight: 'bold',
  },

  gainText: {},

  lossText: {
    background: 'linear-gradient(138.19deg, #F60638 2.08%, #F40469 50.52%, #F20094 94.79%)',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  bottomArea: {
    borderTop: '1px solid #B9B9B9',
    padding: '1.5em',
  },
  sellStatus: {
    minWidth: '64px',
    display: 'inline-block',
    padding: '8px 8px 6px',
    background: colors.p3,
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.n8,
  },

  buyStatus: {
    minWidth: '64px',
    display: 'inline-block',
    padding: '8px 8px 6px',
    background: colors.p4,
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.n8,
  },

  textColored: {
    background: colors.gradients.gradient_title,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
}));

export default useStyles;
