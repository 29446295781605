import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { tradesSelector } from '../../redux/reselect/tradesSelector';
import DataTable from './DataTable';
import { useTheme } from '@mui/material/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const theme = useTheme();
  const { data } = useSelector(tradesSelector);
  const [value, setValue] = useState(0);
  const [tradesCount, setTradesCount] = useState({ open: 0, orders: 0, trades: 0 });

  useEffect(() => {
    const open = data?.results?.filter((item) => item.is_active)?.length || 0;
    const orders = data?.results?.length || 0;
    const trades = data?.results?.filter((item) => item.closed_date)?.length || 0;
    setTradesCount({ open, orders, trades });
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', overflow: 'scroll' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              background: 'transparent',
            },
            '& .Mui-selected': {
              background: theme.palette.primary.mainGradient,
            },
          }}
        >
          <Tab
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              color: value === 0 ? 'white' : theme.palette.text.secondary,
              '&.Mui-selected': {
                color: 'white',
              },
            }}
            label={`Open Orders (${tradesCount.open})`}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              color: value === 1 ? 'white' : theme.palette.text.secondary,
              '&.Mui-selected': {
                color: 'white',
              },
            }}
            label={`Order History (${tradesCount.orders})`}
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              color: value === 2 ? 'white' : theme.palette.text.secondary,
              '&.Mui-selected': {
                color: 'white',
              },
            }}
            label={`Trade History (${tradesCount.trades})`}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DataTable type="open" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DataTable type="order" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DataTable type="trade" />
      </TabPanel>
    </Box>
  );
}
