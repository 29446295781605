import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '32px',
  },
  head: {
    marginBottom: '32px',
    textAlign: 'center',
  },

  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  info: {
    color: '#B1B5C3',
    fontSize: '14px',
    lineHeight: 1.6,
  },
  fieldSet: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '32px',
  },
  field: {
    margin: '0px',
  },
  button: {
    width: '100%',
    marginTop: '8px',
  },
}));

export default useStyles;
