import { takeEvery, fork, put, call } from 'redux-saga/effects';
import ApiTrades from '../../services/trades';

import {
  RETRIEVE_TRADES_ACTION,
  RETRIEVE_TRADES_OK,
  RETRIEVE_TRADES_ERROR,
  RETRIEVE_TRADES_END,
  RETRIEVE_TRADES_SETUP_DATA_ACTION,
  RETRIEVE_TRADES_SETUP_DATA_OK,
  RETRIEVE_TRADES_SETUP_DATA_ERROR,
  RETRIEVE_TRADES_SETUP_DATA_END,
  SET_BUY_TRADE_ACTION,
  SET_BUY_TRADE_OK,
  SET_BUY_TRADE_ERROR,
  SET_BUY_TRADE_END,
} from '../constants/trades.constant';

export function* retrieveTrades(action) {
  try {
    const xhr = yield call(ApiTrades.tradesList, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: RETRIEVE_TRADES_OK, data: xhr.data?.data?.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_TRADES_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TRADES_END });
  }
}

export function* retrieveTradeSetup(action) {
  try {
    const xhr = yield call(ApiTrades.setupTradesData, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: RETRIEVE_TRADES_SETUP_DATA_OK, data: xhr.data?.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: RETRIEVE_TRADES_SETUP_DATA_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TRADES_SETUP_DATA_END });
  }
}

export function* buyTradeSaga(action) {
  const { enqueueSnackbar } = action.params;
  try {
    const xhr = yield call(ApiTrades.buyTrade, action.params.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: SET_BUY_TRADE_OK });
    enqueueSnackbar('Purchased Successfully.', { variant: 'success' });
  } catch (e) {
    console.log('error : ', e);
    enqueueSnackbar(e?.data?.errors, { variant: 'error' });
    yield put({ type: SET_BUY_TRADE_ERROR, ...e });
  } finally {
    yield put({ type: SET_BUY_TRADE_END });
  }
}

export function* retrieveTradesSaga() {
  yield takeEvery(RETRIEVE_TRADES_ACTION, retrieveTrades);
  yield takeEvery(RETRIEVE_TRADES_SETUP_DATA_ACTION, retrieveTradeSetup);
  yield takeEvery(SET_BUY_TRADE_ACTION, buyTradeSaga);
}

const trades_sagas = [fork(retrieveTradesSaga)];
export default trades_sagas;
