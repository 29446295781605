import React, { useEffect, useState } from 'react';

// Components Import
import { Box, Container, Hidden, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Contained as ContainedButton } from '../../components/Button';
import { ErrorIndicator, LoadingIndicator } from '../../components/ContainerIndicator';
import QuizModal from '../../components/Modal';
import ProgressCircular from '../../components/ProgressCircular';
import { getUserPortfolio } from '../../redux/actions/profile.action';
import { quizSelector } from '../../redux/reselect/quizSelector';
import MobileSteppers from './MobileSteppers';
import Options from './Options';
import Steppers from './Steppers';
// Redux Import
import { useDispatch, useSelector } from 'react-redux';

import {
  appendAnswerAction,
  fetchQuizData,
  fetchUserDetails,
  modalToggle,
  saveUserAnswer,
  setIndexAction,
  toggleContinueModal,
  uploadQuizData,
} from '../../redux/actions/quiz.action';

// Assets Import
import useStyle from './index.style';

import { QUIZ_ENTRY_MODAL } from '../../constants/modal';

const RiskToleranceQuiz = (props) => {
  const history = useHistory();
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const styles = useStyle();
  const quiz = useSelector(quizSelector);

  const {
    userQuizDetails = {},
    currentQuestion = {},
    questionNumber,
    answerList = [],
    quizLength,
    questionList = [],
    showModal,
    isLoading,
    showFinishedModal,
    isError,
  } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  useEffect(() => {
    dispatch(fetchQuizData());
  }, []);

  useEffect(() => {
    if (quiz?.userQuizDetails?.completed) {
      history.push(`/app/risk-aware-profile`);
    }
    setIsCompleted(quiz?.userQuizDetails?.completed);
  }, [quiz?.userQuizDetails?.completed]);

  const initBeforeUnLoad = (showExitPrompt) => {};

  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  const handleGoBack = () => {
    if (questionNumber >= 1) {
      dispatch(setIndexAction(questionNumber - 1));
      handlePostAnswer();
    }
  };

  const handleGoNext = () => {
    if (questionNumber < quizLength) {
      dispatch(setIndexAction(questionNumber + 1));
      handlePostAnswer();
    }
  };

  const handlePostAnswer = () => {
    const getQuestion = getCurrentQuestion();

    if (getQuestion) {
      const payload = getPayload();
      const getFunction = getPostFunction(payload);
      dispatch(getFunction(payload));
    }
  };

  const getPostFunction = () => {
    return questionNumber === questionList.length ? uploadQuizData : saveUserAnswer;
  };

  const isQuizFinished = () => {
    return questionNumber === questionList.length;
  };

  const getCurrentQuestion = () => {
    return answerList.find((item) => item.question === currentQuestion.id);
  };

  const getPayload = () => {
    const question = getCurrentQuestion();
    return {
      quiztaker: userQuizDetails.id,
      question: question.question,
      answer: question.id,
    };
  };

  const handleAnswerClick = (option) => {
    const isExisting = answerList.find((item) => item.question === option.question);

    dispatch(
      appendAnswerAction(
        isExisting
          ? [...answerList.filter((item) => item.question !== option.question), option]
          : [...answerList, option]
      )
    );
  };

  const handleModalClose = () => {
    dispatch(modalToggle(false));
    localStorage.setItem(QUIZ_ENTRY_MODAL, true);
  };

  const handleSubmit = () => {
    dispatch(uploadQuizData(getPayload()));
    dispatch(toggleContinueModal(true));

    setTimeout(() => {
      dispatch(fetchUserDetails());
      dispatch(getUserPortfolio());
    }, 2000);
    // setShowResult(true);
  };

  const content = isError ? (
    <ErrorIndicator
      title={`Ooops! Something Went Wrong!`}
      description={'We were not able to fetch your quiz data. Please try again.'}
    />
  ) : (
    <Container className={styles.container}>
      <Box paddingTop="1.5em" paddingBottom="3em">
        {isLoading ? (
          <Box className={styles.loadingContainer}>
            <ProgressCircular loadingText={'Analyzing...'} />
          </Box>
        ) : (
          <>
            <Box
              Box
              alignItems="center"
              display="flex"
              flex="1"
              flexDirection="column"
              width="100%"
            >
              <Hidden smDown>
                <Steppers
                  length={[...Array(quizLength).keys()]}
                  currentStep={questionNumber}
                  answerList={questionList?.map((question) =>
                    answerList.find((item) => item.question === question.id)
                  )}
                  setIndexAction={setIndexAction}
                />
              </Hidden>
              <Typography className={styles.title} variant="h4">
                {currentQuestion.label}
              </Typography>
              <Options
                choices={currentQuestion.answer_set}
                id={currentQuestion.id}
                answerList={answerList}
                onAnswerClick={(e) => handleAnswerClick(e)}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Hidden mdUp>
                <MobileSteppers
                  quizLength={quizLength}
                  activeStep={questionNumber}
                  handleGoNext={handleGoNext}
                  handleGoBack={handleGoBack}
                  handleSubmit={handleSubmit}
                />
              </Hidden>
            </Box>
            <Hidden smDown>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box display="flex" flexDirection="row" mt={6} mr={12}>
                  {questionNumber !== 0 ? (
                    <div className={styles.backBtn} onClick={handleGoBack}>
                      {/* <img className={styles.imgBackIcon} src={left_arrow} alt="back" /> */}
                      PREVIOUS
                    </div>
                  ) : (
                    ''
                  )}
                  {questionNumber !== quizLength - 1 ? (
                    <ContainedButton className={styles.nextBtn} rounded onClick={handleGoNext}>
                      NEXT
                      {/* <img className={styles.imgIcon} src={right_arrow} alt="next-button" /> */}
                    </ContainedButton>
                  ) : (
                    <ContainedButton className={styles.nextBtn} rounded onClick={handleSubmit}>
                      FINISH QUIZ
                    </ContainedButton>
                  )}
                </Box>
              </Box>
            </Hidden>
          </>
        )}
      </Box>
      {/* <Mobile
        handleGoBack={handleGoBack}
        handleGoNext={handleGoNext}
        handleAnswerClick={handleAnswerClick}
      /> */}
    </Container>
  );

  return (
    <Container className={styles.container}>
      {isLoading && !currentQuestion ? <LoadingIndicator /> : content}

      <QuizModal open={showModal} handleClose={handleModalClose} />
      {/* <FinishedModal open={showFinishedModal} /> */}
    </Container>
  );
};

export default RiskToleranceQuiz;
