import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Pie from '../../components/Charts/PieChart';
import useStyle from './index.style';

const TradeOverviewItems = ({ attributes }) => {
  const styles = useStyle();
  const {
    profit_trades,
    loss_trades,
    total_trades,
    closed_stop_loss,
    closed_manual,
    closed_targets,
    current_balance,
    available_balance,
    max_active_trades,
    active_trades,
    total_returns,
    total_profit,
    total_loss,
  } = attributes || {};

  const tradesData = [
    { name: 'Profit Trades', value: 3 },
    { name: 'Loss Trades', value: 2 },
  ];

  const returnsData = [
    { name: 'Total Profit', value: 1 },
    { name: 'Total Loss', value: 2 },
  ];

  const closeData = [
    { name: 'Closed Stoploss', value: 3 },
    { name: 'Closed Manual', value: 9 },
    { name: 'Closed Targets', value: 12 },
  ];

  const getValueOrDash = (value) => value ?? '-';

  const renderPieChart = ({ data, title, totalCount }) => (
    <Box display="flex" justifyContent="center">
      <Pie
        title={title}
        totalCount={totalCount}
        data={data}
        width={200}
        height={200}
        innerRadius={60}
        outerRadius={100}
      />
    </Box>
  );

  return (
    <Grid container>
      <Grid container justifyContent="space-between">
        <Grid item sm={5.5}>
          <Card
            className={styles.card}
            style={{
              flexGrow: 3,
              marginBottom: '0px',
              height: '100%',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                height: '100%',
                overflow: 'hidden',
                padding: '16px',
              }}
            >
              <Typography className={styles.title} variant="h6">
                Balances
              </Typography>
              <table>
                <tbody>
                  <tr>
                    <td>Current Balance:</td>
                    <td style={{ textAlign: 'right' }}>{getValueOrDash(current_balance)}</td>
                  </tr>
                  <tr>
                    <td>Available Balance:</td>
                    <td style={{ textAlign: 'right' }}>{getValueOrDash(available_balance)}</td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={5.5}>
          <Card
            className={styles.card}
            style={{
              flexGrow: 3,
              marginBottom: '0px',
              height: '100%',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                height: '100%',
                overflow: 'hidden',
                padding: '16px',
              }}
            >
              <Typography className={styles.title} variant="h6">
                Active Trades
              </Typography>
              <table>
                <tbody>
                  <tr>
                    <td>Max Active Trades:</td>
                    <td style={{ textAlign: 'right' }}>{getValueOrDash(max_active_trades)}</td>
                  </tr>
                  <tr>
                    <td>Active Trades:</td>
                    <td style={{ textAlign: 'right' }}>{getValueOrDash(active_trades)}</td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Card
          className={styles.card}
          style={{
            flexGrow: 3,
            marginBottom: '0px',
            height: '100%',
          }}
        >
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              height: '100%',
              overflow: 'hidden',
              padding: '16px',
            }}
          >
            {/* <Grid item xs={4}>{renderPieChart({data: tradesData, title: 'Total Trades', totalCount: total_trades})}</Grid>
        <Grid item xs={4}>{renderPieChart({data: returnsData, title: 'Total Returns', totalCount: total_returns})}</Grid>
        <Grid item xs={4}>{renderPieChart({data: closeData, title: 'Trades Closed', totalCount: closed_stop_loss+closed_manual+closed_targets})}</Grid> */}
            <Grid item xs={4}>
              {renderPieChart({ data: tradesData, title: 'Total Trades', totalCount: 5 })}
            </Grid>
            <Grid item xs={4}>
              {renderPieChart({ data: returnsData, title: 'Total Returns', totalCount: 3 })}
            </Grid>
            <Grid item xs={4}>
              {renderPieChart({ data: closeData, title: 'Trades Closed', totalCount: 24 })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TradeOverviewItems;
